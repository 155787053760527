<template>
  <div
    class="library-item-wrap"
    :class="{ 'two-text-rows': isTwoRows, '-horizontal': horizontal }"
  >
    <div
      class="library-item"
      :class="[publication.type.toLowerCase()]"
      @click="goToPublicationPage"
    >
      <div class="book-image-box">
        <BookCover
          :book-id="publication.id"
          :publication="publication"
          :cover-size="coverSize"
        />
        <PublicationProgress :publication-id="publication.id" />
      </div>
      <div class="book-description d-flex flex-column">
        <div class="book-title">
          <NuxtLink
            :to="toNuxtLink"
            :class="[publication.type.toLowerCase()]"
            @click="goToPublicationPage"
          >
            <span>{{ publication.name }}</span>
          </NuxtLink>
        </div>
        <div :class="{ 'd-none': !horizontal }" class="book-author">
          <NuxtLink
            v-if="!isAuthorExcluded(publication.author)"
            :to="authorRoute"
            class="book-author-link"
            >{{ publication.author }}</NuxtLink
          >
          <span v-else class="book-author-link">
            {{ publication.author }}
          </span>
        </div>
        <div class="book-top-stats d-flex justify-space-between">
          <div class="d-flex align-center">
            <ManageFavouritesControl
              v-if="horizontal"
              :publication-id="publication.id"
              icon
              @click.native.prevent.stop
            />
            <TimeDuration
              class-name="d-flex align-center dot"
              :duration="publication.readingTime"
              dir="auto"
            />
            <div
              v-if="publication.audio && isMounted"
              class="d-flex align-center dot"
            >
              <BaseSpriteIcon icon-name="ico-headset-wire" color="#64748b" />
            </div>
            <div v-if="isDownloaded" class="d-flex align-center">
              <BaseSpriteIcon
                icon-name="ico-download-cloud"
                custom-class="download-cloud"
              />
            </div>
          </div>
          <ManageFavouritesControl
            v-if="!horizontal"
            :publication-id="publication.id"
            icon
            @click.native.prevent.stop
          />
        </div>

        <div
          v-if="showLevel && publication.difficulty"
          :class="[horizontal ? 'd-none' : 'd-flex']"
          class="book-bottom-stats align-center"
        >
          <span>{{
            $t('Library.book.item.level', { level: publication.difficulty })
          }}</span>
        </div>
        <div
          v-if="collectionItemsNumberLabel && isMounted"
          class="book-bottom-stats collection-counter"
        >
          {{ collectionItemsNumberLabel }}
        </div>
        <div
          v-if="publication.bookCategory"
          :class="[horizontal ? 'd-none' : 'd-flex']"
          class="book-bottom-stats book-category"
          @click.prevent="goToCategoryPage"
        >
          <span>
            <NuxtLink :to="toNuxtCategoryLink()" @click="goToCategoryPage()">
              {{ getBookCategoryLabel(publication.bookCategory) }}
            </NuxtLink>
          </span>
        </div>
        <div
          v-if="publication.genres && publication.genres.length"
          :class="{ 'd-none': horizontal }"
          class="book-genres"
        >
          <span
            v-for="(genre, index) in publication.genres"
            :key="genre"
            :data-genres-left="publication.genres.length - index - 1"
            class="book-genre"
            @click.prevent="goToGenrePage(genre)"
          >
            <NuxtLink
              :to="toNuxtGenreLink(genre)"
              @click="goToGenrePage(genre)"
            >
              {{ genre }}
            </NuxtLink>
          </span>
        </div>
        <!-- the empty row for books is necessary to prevent jumping of the layout on the Ocean when a collection with extra row appears in the slider -->
        <div
          v-if="showEmptyRow && !isCollection"
          class="book-bottom-stats collection-counter"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import publicationUtils from '@/services/utils/publicationUtils';
import base62 from '@shared/utils/base62.mjs';
import { sizes as thumbSize } from '@shared/enums/ThumbSizesEnum';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import AppStateEnum from '@/enums/AppStateEnum';

import BookCover from '@/components/base/BookCover/BookCover.vue';
import TimeDuration from '@/components/base/TimeDuration/TimeDuration.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import PublicationProgress from '@/components/views/PublicationProgress/PublicationProgress.vue';
import ManageFavouritesControl from '@/components/controls/ManageFavouritesControl/ManageFavouritesControl.vue';

export default {
  name: 'LibraryItem',
  components: {
    BookCover,
    TimeDuration,
    BaseSpriteIcon,
    PublicationProgress,
    ManageFavouritesControl
  },
  props: {
    publication: Object,
    showEmptyRow: Boolean,
    horizontal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      toNuxtLink: publicationUtils.getPublicationLink(this.publication),
      coverSize: thumbSize.LARGE,
      isMounted: false,
      authorRoute: {
        name: AppStateEnum.AUTHOR_PAGE,
        params: {
          authorHash: base62.stringToBase62(this.publication.author.trim()),
          author: this.publication.author
        }
      }
    };
  },
  computed: {
    isCollection() {
      return this.publication.type === PublicationsTypes.COLLECTION;
    },
    showLevel() {
      return (
        this.$store.getters['ContextStore/showPublicationProgress'] &&
        !this.isCollection
      );
    },
    isTwoRows() {
      return (
        this.publication.bookCategory ||
        (this.showLevel && this.publication.difficulty)
      );
    },
    isDownloaded() {
      return (
        this.publication.isContentDownloaded &&
        this.publication.isAudioDownloaded
      );
    },
    collectionItemsNumberLabel() {
      const items = this.publication.items || [];
      const numberBooks = items.length;
      return numberBooks
        ? this.$t('Library.collection.book.label', { numberBooks })
        : '';
    }
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    getBookCategoryLabel(bookCategory) {
      return publicationUtils.getCategoryLocalizationKey(
        bookCategory,
        this.$t.bind(this)
      );
    },
    goToCategoryPage() {
      this.$router.push(
        this.$store.getters['LibraryStore/toCategoryLink'](
          this.publication,
          true
        )
      );
    },
    goToGenrePage(genre) {
      this.$router.push(
        this.$store.getters['LibraryStore/toGenreLink'](this.publication, genre)
      );
    },
    toNuxtGenreLink(genre) {
      return this.$store.getters['LibraryStore/toGenreLink'](
        this.publication,
        genre
      );
    },
    toNuxtCategoryLink() {
      return this.$store.getters['LibraryStore/toCategoryLink'](
        this.publication,
        true
      );
    },
    goToPublicationPage() {
      this.$router.push(publicationUtils.getPublicationLink(this.publication));
    },
    isAuthorExcluded(author) {
      const excludedAuthors = ['unknown', 'anonymous'];
      return excludedAuthors.includes(author.toLowerCase());
    }
  }
};
</script>

<style lang="less">
.library-item-wrap {
  --item-book-description-pt: 8px;
  --items-text-rows: 1;
  --library-item-height: calc(
    var(--item-image-height) + var(--item-book-description-pt) + 20px *
      var(--items-text-rows)
  );

  &.two-text-rows {
    --items-text-rows: 2;
  }

  &.-horizontal {
    .library-item {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      gap: 8px;

      .book-description {
        overflow: hidden;
        padding-top: calc(var(--item-book-description-pt) / 2);
        justify-content: space-between;
      }

      .book-title {
        display: block;
        white-space: nowrap;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inherit;
        }

        a {
          overflow: hidden;
        }
      }
    }

    .book-author {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .book-top-stats {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .book-image-box {
      max-width: 54px;
    }

    .publication-progress {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  min-height: var(--library-item-height);

  .library-item {
    display: block;
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #0f172a;
    text-decoration: none;
    cursor: pointer;

    .night-theme-template & {
      color: #acacac;
    }

    .book-image-box {
      position: relative;

      .publication-progress {
        position: absolute;
        left: 18px;
        right: 18px;
        bottom: 3px;
      }
    }

    .book-description {
      padding-top: var(--item-book-description-pt);
    }

    .book-title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      white-space: wrap;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      word-break: break-word;
      text-overflow: ellipsis;
      color: unset;
      :hover {
        text-decoration: underline;
      }
      body.ocean-app & {
        -webkit-line-clamp: 3;
      }

      span {
        white-space: normal;
      }
      a {
        color: unset;
      }
    }

    .book-top-stats {
      color: #64748b;

      .dot:not(:first-child) {
        &:before {
          content: '•';
          margin-inline-start: 0.3em;
        }
      }

      .download-cloud {
        fill: var(--primary-color);
      }
    }
    .book-bottom-stats {
      color: #0f172a;

      .night-theme-template & {
        color: #acacac;
      }
    }

    .collection-counter {
      min-height: 20px;
    }
    .book-category,
    .book-genres {
      color: var(--primary-color);

      .night-theme-template & {
        color: var(--primary-color);
      }
    }
    .book-category {
      min-width: 0;
      span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .book-genres {
      display: none;
      a {
        text-decoration: none;
      }
      .ffa-app & {
        display: flex;
      }
      flex-wrap: wrap;
      height: 20px;
      overflow: hidden;
      padding-inline-end: 18px;

      .book-genre {
        position: relative;
        z-index: 1;
        background-color: #fff;

        .sepia-theme-template & {
          background-color: #f5eddf;
        }

        .night-theme-template & {
          background-color: #202020;
        }

        &:not(:first-child):before {
          content: ',';
        }

        &:not(:last-child):after {
          content: '+' attr(data-genres-left);
          color: #64748b;
          position: absolute;
          z-index: -1;
          right: -18px;
          [dir='rtl'] & {
            right: auto;
            left: -18px;
          }
        }
      }
    }
    .favourites-button {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
